import { Route, Routes } from '@remix-run/react';

import { PortalLink } from '../../components/PortalContext';
import { MinigameCenter, Wrapper } from '../Minigame';
import {
  MyGameList,
  SearchGameList,
  TagGameList,
  UntaggedGameList,
} from '../Minigame/List';
import { AdminView } from './AdminView';

// eslint-disable-next-line import/no-default-export
export default function MinigameRoute(): JSX.Element {
  return (
    <AdminView
      search={{
        targetPathname: '/admin/minigames/search',
        placeholder: 'Search Minigames',
      }}
      className='bg-library-2023-07'
    >
      <Wrapper routePrefix={PortalLink.AdminHome} mode='admin'>
        <Routes>
          <Route index element={<MinigameCenter />} />
          <Route path={`my`} element={<MyGameList />} />
          <Route path={`tags/:slug`} element={<TagGameList />} />
          <Route path={`search`} element={<SearchGameList />} />
          <Route path={`untagged`} element={<UntaggedGameList />} />
        </Routes>
      </Wrapper>
    </AdminView>
  );
}
